<template>
    <div class="login-page">
        <Header></Header>
        <div class="inviteRule-title">
            <img class="animated bounceIn" src="@/assets/img/letter/upRules.png" alt="" srcset="">
        </div>
        <div class="inviteRule-select animated rotateInDownLeft" @click="lookDetail()">
            <img src="@/assets/img/letter/letter.png" alt="" srcset="">
            <div class="letter-box">
                <!-- {{ Msg }} -->
            </div>
        </div>

        <div class="login-btn">
            <div @click="nextChange()">
                <img src="@/assets/img/letter/btn-up.png" alt="" srcset="">
            </div>
            <div @click="goBack()">
                <img src="@/assets/img/letter/btn-back.png" alt="" srcset="">
            </div>
        </div>

        <loading v-show="loadingShow"></loading>
    </div>
</template>
<script>
import loading from '@/components/loading'
import Header from '@/components/Header'
import Vue from 'vue'
import { Dialog } from 'vant';
Vue.use(Dialog);
import qs from 'qs'
export default {
    data() {
        return {
            loadingShow: false,
            openid: '',
            FullName: '',
            Msg: ''
        }
    },
    components: {
        'loading': loading,
        'Header': Header
    },
    mounted() {
        this.FullName = sessionStorage.getItem('FullName');
        this.getMsg();
    },
    methods: {
        getMsg() {
            let that = this;
            let promise = {
                FullName: that.FullName,
            }
            that.axios.post('/GetMsgRecord',
                promise
            ).then(function (res) {
                console.log(res.data);
                if (res.data.Success) {
                    console.log(res.data.Data)
                    that.Msg = res.data.Data.Msg;
                    if (that.Msg && that.Msg != '') {
                        setTimeout(() => {
                            Dialog.alert({
                                className:'letter-box-content',
                                title: '话题',
                                message: that.Msg,
                            }).then(() => {
                                // on close
                            });
                        }, 1000);
                    }

                } else {
                    Dialog.alert({
                        title: '提示',
                        message: res.data.Message,
                    }).then(() => {
                        // on close
                    });
                }
            })
        },
        lookDetail() {
            if (this.Msg && this.Msg != '') {
                Dialog.alert({
                    className:'letter-box-content',
                    title: '话题',
                    message: this.Msg,
                }).then(() => {
                    // on close
                });
            }
        },
        nextChange() {
            this.$router.replace('/uploadFile')
        },
        goBack() {
            this.$router.replace('/map')
        }
    }
}
</script>
<style scoped>
.inviteRule-title {
    width: 578px;
    margin: 0 auto;
    margin-top: 36px;
}

.inviteRule-select {
    width: 467px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;
}

.letter-box {
    position: absolute;
    padding: 0 19%;
    box-sizing: border-box;
    width: 96%;
    height: 86%;
    top: 0.625rem;
    left: 0;
    border-radius: 1.25rem;
    padding-top: 14%;
    font-size: 1.5rem;
    transform: rotate(-5deg);
    overflow: scroll;
    animation: 5s wordsLoop linear infinite normal;
}

@keyframes slideInX {
    0% {
        top: -120px;
    }

    100% {
        top: 0px;
    }
}

@keyframes slideInY {
    0% {
        top: -120px;
        transform: rotate(45deg);
    }

    100% {
        top: 0px;
        transform: rotate(0deg);
    }
}


.Aquestion {
    width: 397px;
    margin: 0 auto;
    margin-top: 213px;
}

.Aquestion-textarea textarea {
    width: 483px;
    height: 120px;
    margin-top: 25px;
    border-radius: 25px;
    border: 1pt solid #dfdfdf;
    font-size: 24px;
    padding: 10px;
    box-sizing: border-box;
}

.login-btn {
    width: 522px;
    margin: 0 auto;
    margin-top: 45px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 220px);
    grid-row-gap: 39px;
    grid-column-gap: 39px
}

@keyframes ball {
    0% {
        transform: translateY(10vh);
    }

    100% {
        transform: translateY(-20vh);
    }
}

/*旋转的动画*/
@keyframes roll {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*阴影的动画*/
@keyframes shadow {
    0% {
        transform: scale(0.15, 1.25);
    }

    100% {
        transform: scale(1.25, 0.75);
    }

}

@keyframes wordsLoop {
    0% {
        transform: translateY(22%) rotate(-8deg);
        -webkit-transform: translateY(22%) rotate(-8deg);
    }

    100% {
        transform: translateY(-20%) rotate(-8deg);
        -webkit-transform: translateY(-20%) rotate(-8deg);
    }
}

@-webkit-keyframes wordsLoop {
    0% {
        transform: rotate(-5deg);
        -webkit-transform: rotate(-5deg);
        transform: translateY(10%);
        -webkit-transform: translateY(10%);
    }

    100% {
        transform: rotate(-5deg);
        -webkit-transform: rotate(-5deg);
        transform: translateY(-40%);
        -webkit-transform: translateY(-40%);
    }
}

</style>
<style>
.letter-box-content .van-dialog__content{
    height: 25vh;
    overflow: scroll;
}
</style>