<template>
  <div class="login-page">
    <Header></Header>
    <div class="tip">
      <img src="@/assets/img/deliver/openLight.png" alt="" srcset="" />
    </div>
    <div id="main"></div>

    <div class="shield" v-if="letterShow">
      <img src="@/assets/img/map/deliver.png" alt="" srcset="" />
    </div>
    <div class="login-btn" @click="nextChange()" v-else>
      <img src="@/assets/img/map/deliver.png" alt="" srcset="" />
    </div>
    <div class="letter-box" v-if="letterShow">
      <div class="left">
        <img src="@/assets/img/letter/letter-tip.png" alt="" srcset="" />
      </div>
      <div class="right" @click="openLetter()">
        <span>{{ num }}</span>
        <img src="@/assets/img/letter/letter-msg.png" alt="" srcset="" />
      </div>
    </div>

    <loading v-show="loadingShow"></loading>
    <van-dialog v-model="medalShow" title="" v-if="showXZ">
      <p class="medal-text">您是{{ Region }}第{{ RegionTop }}位点亮者</p>
      <p class="medal-text">全国第{{ CountryTop }}位点亮者</p>
      <img
        class="medal-img"
        src="https://ossqixqir.oss-cn-beijing.aliyuncs.com/wj/0524/medal.png"
      />
    </van-dialog>
    <van-overlay :show="show" @click="overlayChange()">
      <img class="imgSrc-box" :src="imgSrc" alt="" srcset="" />
    </van-overlay>
  </div>
</template>
<script>
import loading from "@/components/loading";
import Header from "@/components/Header";
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import qs from "qs";
import geoJson from "echarts/map/json/china";
export default {
  data() {
    return {
      show: false,
      userInfoListSelct: [
        "北京市",
        "天津市",
        "河北省",
        "山西省",
        "内蒙古自治区",
        "辽宁省",
        "吉林省",
        "黑龙江省",
        "上海市",
        "江苏省",
        "浙江省",
        "安徽省",
        "福建省",
        "江西省",
        "山东省",
        "河南省",
        "湖北省",
        "湖南省",
        "广东省",
        "广西壮族自治区",
        "海南省",
        "四川省",
        "贵州省",
        "云南省",
        "重庆市",
        "西藏自治区",
        "陕西省",
        "甘肃省",
        "青海省",
        "宁夏回族自治区",
        "新疆维吾尔自治区",
        "香港特别行政区",
        "澳门特别行政区",
        "台湾省",
      ],
      imgSrc:
        "https://ossqixqir.oss-cn-beijing.aliyuncs.com/wj/province/10.png",
      loadingShow: false,
      openid: "",
      FullName: "",
      Region: "",
      RegionTop: 1,
      CountryTop: 1,
      num: 1,
      medalShow: false,
      letterShow: false,
      map: [],
      //展示勋章弹窗
      showXZ: true,
      xzId: "",
    };
  },
  components: {
    loading: loading,
    Header: Header,
  },
  mounted() {
    this.xzId = sessionStorage.getItem("pfizer_xz");
    if(this.xzId ==='2'){
        this.showXZ = false
    }
    this.openid = sessionStorage.getItem("openid");
    this.FullName = sessionStorage.getItem("FullName");
    this.Region = sessionStorage.getItem("Region");
    let myChart = this.$echarts.init(document.getElementById("main"));
    var index =
      this.findArrIndex(
        this.userInfoListSelct,
        sessionStorage.getItem("Region")
      ) + 1;
    (this.imgSrc =
      "https://ossqixqir.oss-cn-beijing.aliyuncs.com/wj/province/" +
      index +
      ".png"),
      this.getMsgNum();
    this.UpMedalChange();
    this.map.push({
      areaName: sessionStorage.getItem("Region"),
    });
    let a = JSON.stringify(this.map)
      .replace(/customerNum/g, "value")
      .replace(/areaName/g, "name");
    var outdata = this.backgroundColorFunc(JSON.parse(a));
    // 注册的是中国地图，必须包括geo组件或者mep图标类型的时候才可以使用
    // 地图：世界地图，中国地图，省份地图，市区地图
    this.$echarts.registerMap("china", geoJson);
    console.log(sessionStorage.getItem("LoginTag"));
    if (
      !sessionStorage.getItem("LoginTag") ||
      sessionStorage.getItem("LoginTag") == "false"
    ) {
      this.GetMedal();
    }
    //this.$echarts.registerMap('甘肃', 'https://geo.datav.aliyun.com/areas_v2/bound/620000_full.json');//获取地图数据
    myChart.setOption({
      // 配置项(组件)
      series: [
        // 配置地图相关的数据参数
        {
          type: "map",
          label: {
            normal: {
              // 显示文字
              show: true,
              textStyle: {
                color: "rgb(169,34,124)",
              },
            },
            emphasis: {
              textStyle: {
                color: "rgb(183,185,14)",
              },
            },
          },
          roam: true, // 是否可以缩放地图
          // 图层
          zoom: 1.2,
          map: "china",
          itemStyle: {
            normal: {
              // 背景色
              backgroundColor: "rgb(147,235,248)",
              // 边框
              borderWidth: 1,
              borderColor: "rgb(220,220,220)",
              // 区域颜色
              areaColor: {
                type: "radial",
                x: 0.5,
                y: 0.5,
                // 文档
                r: 0.8,
                colorStops: [
                  { offset: 0, color: "rgb(118,201,219)" },
                  { offset: 1, color: "rgb(118,201,219)" },
                ],
                // 全局生效
                globalCoord: true,
              },
            },
            // 高亮效果
            emphasis: {
              areaColor: "rgb(150,30,95)",
              borderWidth: 0.1,
            },
          },
          data: outdata,
          animation: true,
        },
      ],
    });

    //设置勋章显示一次
    sessionStorage.setItem("pfizer_xz", 2);
  },
  methods: {
    backgroundColorFunc(data) {
      let sortData = data.sort(function (a, b) {
        return b.value - a.value;
      });
      sortData.forEach((element, index) => {
        element.sort = index + 1;
        element.name = element.name.replace(
          /省|市|壮族自治区|特别行政区|回族自治区|特别行政区|维吾尔自治区|自治区/g,
          ""
        );
        element.itemStyle = {
          normal: {
            areaColor: "", //地图颜色
            borderColor: "#fff",
            borderWidth: 1,
          },
        };
        if (index === 0) {
          element.itemStyle.normal.areaColor = "#a60060";
        } else if (index === 1) {
          element.itemStyle.normal.areaColor = "#a60060";
        } else if (index === 2) {
          element.itemStyle.normal.areaColor = "#a60060";
        } else if (index > 2 && index < 10) {
          element.itemStyle.normal.areaColor = "#a60060";
        } else {
          element.itemStyle.normal.areaColor = "#a60060";
        }
      });
      return sortData;
    },

    getMsgNum() {
      let that = this;
      let promise = {
        FullName: that.FullName,
      };
      that.axios.post("/GetMsgCount", promise).then(function (res) {
        console.log(res.data);
        if (res.data.Success && res.data.Data && res.data.Data > 0) {
          console.log(res.data.Data);
          that.letterShow = true;
        } else {
          that.show = false;
          that.getMsg();
        }
      });
    },

    getMsg() {
      let that = this;
      let promise = {
        FullName: sessionStorage.getItem("FullName"),
      };
      that.axios.post("/GetMsgRecord", promise).then(function (res) {
        if (res.data.Succes && res.data.Data && res.data.Data.id) {
          sessionStorage.setItem("userid", res.data.Data.id);
        }
      });
    },

    GetMedal() {
      let that = this;
      let promise = {
        OpenID: that.openid,
      };
      that.axios.post("/GetMedal", promise).then(function (res) {
        console.log(res.data);
        that.RegionTop = res.data.RegionTop;
        that.CountryTop = res.data.CountryTop;
        that.medalShow = true;
      });
    },
    overlayChange() {
      this.medalShow = true;
      this.show = false;
    },
    openLetter() {
      this.$router.replace("/letter");
    },
    UpMedalChange() {
      let that = this;
      let promise = {
        OpenID: that.openid,
        Region: that.Region,
      };
      that.axios.post("/UpMedal", promise).then(function (res) {
        //that.$router.replace('/deliver')
      });
    },
    nextChange() {
      let that = this;
      that.$router.replace("/deliver");
    },
    findArrIndex(arrs, element) {
      for (var i = 0; i < arrs.length; i++) {
        // 循环遍历数组每一项与指定的元素进行比较
        if (arrs[i] == element) {
          return i;
        }
      }
    },
  },
};
</script>
<style scoped>
.ruleTitle {
  width: 591px;
  margin: 0 auto;
  margin-top: 44px;
}

.login-btn {
  width: 222px;
  margin: 0 auto;
  margin-top: 76px;
  animation: bounce 2s ease-in-out infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
    transform: scale(1);
  }

  40% {
    transform: translateY(-30px);
    transform: scale(1.2);
  }

  60% {
    transform: translateY(-15px);
    transform: scale(1);
  }
}

@keyframes flash {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.rule-content {
  width: 630px;
  margin: 0 auto;
  margin-top: 64px;
}

#main {
  width: 100%;
  height: 600px;
  margin-top: 10px;
}

.tip {
  width: 100px;
  position: absolute;
  top: 36%;
  left: 45%;
  animation: flash 3s ease-in-out infinite;
  z-index: 999;
}

@keyframes breath-effect {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }

  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0);
  }
}

.letter-box .left {
  width: 13.25rem;
  position: absolute;
  bottom: 3%;
  right: 19vw;
}

.letter-box .right {
  width: 100px;
  position: absolute;
  bottom: 2%;
  right: 4vw;
  animation: wobble 2s ease-in-out infinite;
}

.letter-box .right span {
  position: absolute;
  left: 4pt;
  top: -3pt;
  color: #fff;
  font-size: 20px;
}

@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }

  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
  }

  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

.shield {
  width: 222px;
  margin: 0 auto;
  margin-top: 36px;
}

.shield img {
  filter: grayscale(100%) !important;
}

.medal-text:nth-last-of-type(1) {
  margin-bottom: 20px;
}

.medal-text {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  margin-top: 20px;
  box-sizing: border-box;
  line-height: 1.5;
  color: #a60060;
}

.medal-img {
  width: 60%;
}

.imgSrc-box {
  position: absolute;
  top: 43%;
  width: 47%;
  left: 29%;
}
</style>