<template>
  <div class="login-page">
    <Header></Header>
    <div class="inviteRule-title">
      <img v-if="inviteRule5Show" src="@/assets/img/deliver/inviteRule5.png" alt="" srcset="" />
      <img v-else src="@/assets/img/deliver/inviteRule.png" alt="" srcset="" />
    </div>
    <div class="inviteRule-select" @click="getuserlist()">
      <input readonly type="text" placeholder="选择医生" v-model="chooseDoc" />
      <!-- <van-field readonly clickable name="type" :value="houseutilizationValue" placeholder="选择医生"
                @click="houseutilizationType" /> -->
    </div>
    <div class="Aquestion">
      <img src="@/assets/img/deliver/Aquestion.png" alt="" srcset="" />
    </div>

    <!-- 第二代选择问题按钮 -->
    <div class="question-select" @click="getQuestion()">
      <!-- <input readonly type="text" placeholder="选择题库" v-model="chooseQue" /> -->
      <input readonly type="text" placeholder="选择题库" v-model="Msg" />
    </div>

    <!-- 第一代选择问题输入框 -->
    <div class="Aquestion-textarea" v-if="showSecond">
      <textarea placeholder="请输入请他问题..." v-model="otherMsg" name="" id="" cols="30" rows="10"></textarea>
      <!-- <input type="text" placeholder="请提问" v-model="Msg" name="" id=""> -->
    </div>

    <div class="login-btn">
      <!-- 新增的返回按钮 -->
      <img src="@/assets/img/deliver/feiShu.png" @click="nextChange()" alt="" srcset="" />
      <img src="@/assets/img/letter/goEnd.png" @click="goEnd()" alt="" />
    </div>
    <!-- 选择医生页面 -->
    <div>
      <div v-if="showType" class="popup-bg">
        <div class="btn">
          <div @click="cancel" class="cancle">取消</div>
          <div @click="confirm" class="confirm">确定</div>
        </div>
        <div class="popup">
          <van-tree-select max="1" @click-item="clickSelectItem" :items="items" :active-id.sync="activeIds"
            :main-active-index.sync="activeIndex" />
        </div>
      </div>
    </div>
    <!-- 选择问题弹窗 -->
    <div>
      <vanPickerSelect ref="questionSelect" @onPickerConfirm="onPickerConfirm" @func="getMsgquestion"></vanPickerSelect>
    </div>
    <!-- 加载中页面 -->
    <loading v-show="loadingShow"></loading>
  </div>
</template>
<script>
import vanPickerSelect from "@/components/van-picker-select";
import loading from "@/components/loading";
import Header from "@/components/Header";
import Vue from "vue";
import { Dialog } from "vant";
import { TreeSelect } from "vant";
import { Picker } from "vant";
import { Popup } from "vant";

Vue.use(TreeSelect);
Vue.use(Dialog);
Vue.use(Picker, Popup);
import qs from "qs";
export default {
  data() {
    return {
      questions: [
        "您对奥加伊妥珠单抗的初体验?",
        "您对奥加伊妥珠单抗在哪类人群的使用效果更感兴趣?",
        "您对奥加伊妥珠单抗在哪类人群的使用效果印象深刻?",
        "您对移植前/后使用奥加伊妥珠单抗的方案建议?",
        "您在什么情况下会使用奥加伊妥珠单抗序贯BiTE/CART的方案?",
        "您在什么情况下会使用奥加伊妥珠单抗联合化疗方案?",
        "其他",
      ],
      loadingShow: false,
      openid: "",
      FullName: "",
      userInfoList: [],
      userInfoListSelct: [],
      chooseDoc: "",
      chooseQue: "",
      chooseDocArr: [],
      Msg: "",
      otherMsg: "",
      inviteRule5Show: true,
      showType: false,
      activeIndex: 0,
      items: [
        {
          text: "w",
          children: [
            { text: "吴杰", id: 1 },
          ],
        },
        {
          text: "L",
          children: [
            { text: "李四", id: 5 },
          ],
        },
      ],
      activeIds: [],
      activeIdsStr: "",
      activeIdsDetail: [],
      activeIndex: 0,
      showFirst: true,
      showSecond: false,
      clickNum: 5,
      otherShow: false,
    };
  },
  components: {
    loading: loading,
    Header: Header,
    vanPickerSelect: vanPickerSelect,
  },
  mounted() {
    this.openid = sessionStorage.getItem("openid");
    this.FullName = sessionStorage.getItem("FullName");
    this.getMsgNum();
  },
  methods: {
    getuserlist() {
      let that = this;
      that.showType = true;
      that.axios.get("/GetUserList").then(function (res) {
        if (res.data.Success && res.data.Code == "1") {
          if (res.data.Data && res.data.Data.length > 0) {
            res.data.Data = JSON.parse(
              JSON.stringify(res.data.Data).replace(/"FullName"/g, '"text"')
            );
            console.log(res.data.Data);
            that.items = res.data.Data;
            //that.houseutilizationColumns = res.data.Data
          }
          that.loadingShow = false;
        } else {
          that.loadingShow = false;
          Dialog.alert({
            title: "提示",
            message: res.data.Message,
          }).then(() => {
            // on close
          });
        }
      });
    },
    getQuestion() {
      this.$refs.questionSelect.onPickerConfirm(this.questions);
    },
    //传值
    onPickerConfirm(res) {
      tihs.$emit(res);
    },
    //取值
    getMsgquestion(data) {
      this.Msg = data;
      if (this.Msg === '其他') {
        this.showFirst = false
        this.showSecond = true
      } else {
        this.showFirst = true
        this.showSecond = false
      }
    },
    onQuestion(picker, value, index) {
      console.log("1", picker, "2", value, "3", index);
    },
    getMsgNum() {
      let that = this;
      let promise = {
        FullName: that.FullName,
      };
      that.axios.post("/GetMsgSelectCount", promise).then(function (res) {
        console.log(res.data);
        if (res.data.Success && res.data.Data && res.data.Data > 0) {
          that.inviteRule5Show = false;
          that.clickNum = 3;
        } else {
          that.inviteRule5Show = true;
          that.clickNum = 5;
        }
      });
    },
    //结束传递
    goEnd() {
      this.$router.replace("/endpage");
    },
    nextChange() {
      let that = this;
      if ((that.Msg != '' || that.otherMsg != '') && that.chooseDoc == "") {
        Dialog.alert({
          title: "提示",
          message: '请选择医生',
        }).then(() => {
          // on close
          //that.$router.replace("/endPage");
        });
        return
      } else {
        var MsgUp = '';
        if (that.showSecond) {
          MsgUp = that.otherMsg
        } else {
          MsgUp = that.Msg
        }
        let promise = {
          OpenID: that.openid,
          FullName: that.activeIdsStr,
          Msg: MsgUp,
        };
        that.axios.post("/UpMsg", promise).then(function (res) {
          console.log(res.data);
          Dialog.alert({
            title: "提示",
            message: '提交成功',
          }).then(() => {
            if (that.clickNum >= 1) {
              that.clickNum--;
              that.chooseDoc = "";
              that.Msg = "";
              that.otherMsg = "";
              that.showFirst=true;
              that.showSecond=false;
              that.activeIds = [];
            } else {
              that.$router.replace("/endPage");
            }
            // on close
            //that.$router.replace("/endPage");
          });
        });

      }
      //this.$router.replace('/letter')
    },

    confirm() {
      this.showType = false;
    },
    cancel() {
      this.showType = false;
    },
    clickSelectItem(data) {
      console.log(data)
      this.activeIdsDetail.push(data);
      var res = [...new Set(this.activeIdsDetail)].filter((item) =>
        this.activeIds.includes(item.id)
      );
      console.log(res);
      if(res && res[0] &&  res[0].text){
        this.chooseDoc = res[0].text;
      }else{
        this.chooseDoc = '';
      } 
      this.activeIdsStr = this.activeIds.join(",");
      console.log(this.activeIdsStr)
    },
  },
};
</script>
<style scoped>
.inviteRule-title {
  width: 326px;
  margin: 0 auto;
  margin-top: 44px;
}

.inviteRule-select {
  width: 583px;
  margin: 0 auto;
  margin-top: 40px;
  position: relative;
}

.question-select {
  width: 583px;
  margin: 0 auto;
  margin-top: 40px;
  position: relative;
}

.inviteRule-select input {
  border: none;
  border-radius: 25px;
  width: 100%;
  height: 86px;
  background: url("../assets/img/deliver/chooseDoc.png") no-repeat center;
  background-size: 100% 100%;
  font-size: 30px;
  text-align: center;
}

.question-select input {
  border: none;
  border-radius: 25px;
  width: 100%;
  height: 86px;
  background: url("../assets/img/deliver/choosequestion.png") no-repeat center;
  background-size: 100% 100%;
  font-size: 20px;
  text-align: center;
}

.inviteRule-select input::-webkit-input-placeholder {
  /* 使用webkit内核的浏览器 */
  font-size: 30px;
  font-weight: 700;
}

.question-select input::-webkit-input-placeholder {
  /* 使用webkit内核的浏览器 */
  font-size: 30px;
  font-weight: 700;
}

.inviteRule-select input:-moz-placeholder {
  /* Firefox版本4-18 */
  font-size: 30px;
  font-weight: 700;
}

.question-select input:-moz-placeholder {
  /* Firefox版本4-18 */
  font-size: 30px;
  font-weight: 700;
}

.inviteRule-select input::-moz-placeholder {
  /* Firefox版本19+ */
  font-size: 30px;
  font-weight: 700;
}

.question-select input::-moz-placeholder {
  /* Firefox版本19+ */
  font-size: 30px;
  font-weight: 700;
}

.inviteRule-select input:-ms-input-placeholder {
  /* IE浏览器 */
  font-size: 30px;
  font-weight: 700;
}

.question-select input:-ms-input-placeholder {
  /* IE浏览器 */
  font-size: 30px;
  font-weight: 700;
}

.Aquestion {
  width: 397px;
  margin: 0 auto;
  margin-top: 43px;
}

.Aquestion-textarea textarea {
  width: 583px;
  height: 160px;
  line-height: 35px;
  margin-top: 20px;
  border-radius: 45px;
  border: 1pt solid #a60060;
  font-size: 20px;
  box-sizing: border-box;
  padding: 10px 30px;
}

.Aquestion-textarea input {
  width: 583px;
  height: 80px;
  margin-top: 40px;
  border-radius: 40px;
  border: 1pt solid #a60060;
  font-size: 30px;
  padding: 10px;
  box-sizing: border-box;
  padding: 0 10px;
  text-align: center;
  font-weight: 700;
}

.login-btn {
  margin: 0 auto;
  margin-top: 76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.login-btn img {
  display: block;
  width: 222px;
}

div /deep/.van-ellipsis {
  font-size: 25px;
}
</style>
<style>
.popup-bg {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.btn {
  width: 100%;
  position: absolute;
  bottom: 25rem;
  left: 0;
  display: flex;
  justify-content: space-between;
  background: #fff;
}

.cancle {
  padding: 15px 0px 15px 18px;
  font-size: 5vw !important;
  color: #969799;
}

.confirm {
  padding: 15px 18px 15px 0px;
  font-size: 5vw !important;
  color: #a60060;
}

.van-sidebar-item--select::before {
  color: #a60060;
}

.van-sidebar-item__text {
  font-size: 38px !important;
}

.van-tree-select__item {
  font-size: 32px !important;
  height: auto;
  overflow: scroll;
  margin: 10px 0;
}

.van-tree-select__nav-item {
  height: 10vw;
}

div /deep/.van-tree-select__item {
  color: #969799;
  line-height: 4rem;
  font-size: 24px !important;
}

.van-tree-select__item--active {
  color: #a60060 !important;
}

.popup {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 400px;
  overflow: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
}

.item {
  padding: 0 15px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #f7f8f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected {
  width: 16px;
  height: 16px;

  background-size: 16px auto;
}

.inviteRule-select .van-field__body input {
  border: none;
  border-radius: 25px;
  width: 100%;
  height: 86px;
  background: url("../assets/img/deliver/chooseDoc.png") no-repeat center;
  background-size: 100% 100%;
  font-size: 30px;
  text-align: center;
}

.inviteRule-select .van-cell {
  font-size: 24px;
}
</style>