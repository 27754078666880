import OSS from "ali-oss";
import { fetch } from "@/common/util/http.js";

export default {
  dir: "2023/cp/feishu",
  random_string(len) {
    len = len || 32;
    var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
    var maxPos = chars.length;
    var pwd = "";
    for (var i = 0; i < len; i++) {
      pwd += chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return "_"+pwd;
  },
  get_suffix(filename) {
    var pos = filename.lastIndexOf(".");
    var suffix = "";
    if (pos !== -1) {
      suffix = filename.substring(pos);
    }
    return suffix;
  },
  init() {
    fetch("https://webapi.qixqir.com/api/oss/getstsuser", {itemno:"S561294c94f50708c5d671b2D1H6D58"})
      .then((res) => {
        this.client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: res.Data.AccessKeyId,
          accessKeySecret: res.Data.AccessKeySecret,
          stsToken: res.Data.SecurityToken,
          bucket: "ossqixqir",
          // region: res.region,
          // accessKeyId: res.data.accessKeyId,
          // accessKeySecret: res.data.accessKeySecret,
          // stsToken: res.data.securityToken,
          // bucket: res.bucketName,
        });
      })
      .catch((err) => {
        reject(err);
      });
  },
  dataURLtoBlob: function (dataurl,videurl) {
    let arr = dataurl.split(","),
      mime = videurl?videurl:arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  },
  uploadBase64: function (base64,username,filetype) {
    var blob = this.dataURLtoBlob(base64,filetype);
    var storeAs =
      this.dir +"/" +username+this.random_string(8) +"." +blob.type.substr(blob.type.lastIndexOf("/") + 1);
    return this.upload(storeAs, blob);
  },
  uploadFile: function (file) {
    var storeAs =
      this.dir + "/" + this.random_string(8) + this.get_suffix(file.name);
    return this.upload(storeAs, file);
  },
  async upload(name, file) {
    return this.client.put(name, file).then((res) => {
      return "https://oss.qixqir.com/" + res.name;
    });
  },
};
