<template>
    <div class="index-page" id="start">
        <Header></Header>
        <!-- openInfo -->
        <div class="openInfo-box">
            <img class="animated zoomIn" src="@/assets/img/index/openInfo.png" alt="" srcset="">
        </div>

        <!-- next -->
        <div @click="goRule()" class="next-box">
            <img src="@/assets/img/index/next.png" alt="" srcset="">
        </div>

        <loading v-show="loadingShow"></loading>
    </div>
</template>
<script>
import loading from '@/components/loading'
import Header from '@/components/Header'
export default {
    data() {
        return {
            loadingShow: false
        }
    },
    components: {
        'loading': loading,
        'Header': Header
    },
    mounted() {
        let openid = this.getQueryString('openid');
        if (openid) {
            console.log(openid)
            sessionStorage.setItem('openid',openid)
        }
        // 给被滑动对象添加事件
        let element = document.getElementById('start')
        this.addHandler(element, 'touchstart', this.handleTouchEvent)
        this.addHandler(element, 'touchend', this.handleTouchEvent)
        this.addHandler(element, 'touchmove', this.handleTouchEvent)
    },
    methods: {
        // 给目标添加事件，处理兼容
        addHandler(element, type, handler) {
            if (element.addEventListener) {
                element.addEventListener(type, handler, false)
            } else if (element.attachEvent) {
                element.attachEvent('on' + type, handler)
            } else {
                element['on' + type] = handler
            }
        },
        // 具体的滑动处理
        handleTouchEvent(event) {
            switch (event.type) {
                case 'touchstart':
                    this.startX = event.touches[0].pageX
                    this.startY = event.touches[0].pageY
                    break
                case 'touchend':
                    var spanX = event.changedTouches[0].pageX - this.startX
                    var spanY = event.changedTouches[0].pageY - this.startY
                    // console.log('spanY', spanY)
                    if (spanY < -30) { // 向上
                        console.log(this.getQueryString('LoginTag'))
                        if(this.getQueryString('LoginTag') == '1'){//已登录过
                            sessionStorage.setItem('LoginTag',true)
                            this.$router.replace('/map')
                        }else{
                            sessionStorage.setItem('LoginTag',false)
                            this.$router.replace('/login')
                        }
                    }
                    if (Math.abs(spanX) > Math.abs(spanY)) {
                        // 认定为水平方向滑动
                    } else {
                        // 认定为垂直方向滑动
                        console.log('垂直滑动')
                    }
                    break
                case 'touchmove':
                    // 阻止默认行为
                    // event.preventDefault()
                    break
            }
        },
        getQueryString(name) {
            let geturl = window.location.href;
            let getqyinfo = geturl.split('?')[1];  
            let getqys = new URLSearchParams('?' + getqyinfo);
            let URLname = getqys.get(name);   
            return URLname;
           
        },
        goRule(){
            this.$router.replace('/login')
        }
    }
}
</script>
<style scoped>
.index-page {
    background: #fff;
    width: 100%;
    height: 100%;
}

.openInfo-box {
    width: 80.4%;
    margin: 0 auto;
    margin-top: 52px;
}

.next-box {
    width: 66px;
    margin: 0 auto;
    margin-top: 40px;
    animation: fingerHandle 2s ease infinite both;
}

@keyframes fingerHandle {
    0% {
        transform: none;
    }

    70% {
        transform: scale3d(.8, .8, .8);
        margin-top: 80px;
    }

    100% {
        transform: none;
    }
}
</style>