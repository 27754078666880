import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import './util/rem'
import Vant from 'vant';
import 'vant/lib/index.css';
import { Picker } from 'vant';
import { Dialog } from 'vant';
import { DatetimePicker } from 'vant';
import echarts from 'echarts'
import { post, fetch, patch, put } from '@/common/util/http.js'
//必须引入的核心，换成require也是一样的。注意：recorder-core会自动往window下挂载名称为Recorder对象，全局可调用window.Recorder，也许可自行调整相关源码清除全局污染
import Recorder from 'recorder-core'

//需要使用到的音频格式编码引擎的js文件统统加载进来
import 'recorder-core/src/engine/wav'

//以上三个也可以合并使用压缩好的recorder.xxx.min.js
//比如 import Recorder from 'recorder-core/recorder.mp3.min' //已包含recorder-core和mp3格式支持
//需要使用到的音频格式编码引擎的js文件统统加载进来
import 'recorder-core/src/engine/mp3'
import 'recorder-core/src/engine/mp3-engine'

//定义全局变量
Vue.prototype.$post = post
Vue.prototype.$fetch = fetch
Vue.prototype.$patch = patch
Vue.prototype.$put = put

Vue.prototype.$echarts = echarts
Vue.use(DatetimePicker);
Vue.use(Dialog);
Vue.use(Picker);
Vue.use(Vant);
Vue.use(VueRouter,)
Vue.use(VueAxios, axios)
Vue.use(require('vue-cookies'))
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$wx = wx


axios.defaults.baseURL = 'http://webapi.qixqir.com/api/C20230518'  //关键代码
// 是否允许跨域携带cookie信息
// axios.defaults.withCredentials = true;
// 配置请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
