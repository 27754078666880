<template>
    <div class="loading">
        <div class="mock">数据加载中...</div>
        <div class="loader-inner ball-pulse">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>
<script>
    export default {
        data(){
            return{

            }
        }
    };
</script>

<style scoped>
  .loading{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index:30;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.6;
  }
  .loading .mock{
    height: 30px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 25px;/*px*/
    color: #03a9f4;
    /*text-align: center;*/
    line-height: 30px;
  }
  .ball-pulse{
    position: absolute;
    top: 50%;
    left: 45%;
    transform: translateX(-22%);
  }
  .loading .ball-pulse div{
    background-color: #03a9f4;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    margin: 2px;
    animation-fill-mode: both;
    display: inline-block;
  }
  .loading .ball-pulse div:nth-child(1){
    animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
  .loading .ball-pulse div:nth-child(2){
    animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  }
  .loading .ball-pulse div:nth-child(3){
    animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08)
  }


@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  45% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.7;
  }
  80% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
</style>