import Vue from 'vue'
import Router from 'vue-router'

import Login from "@/view/login.vue";
import Index from "@/view/index.vue";
import Rules from "@/view/rules.vue";
import Map from "@/view/map.vue";
import Deliver from "@/view/deliver.vue";
import Letter from "@/view/letter.vue"; 
import UploadFile from "@/view/uploadFile.vue"; 
import EndPage from "@/view/endPage.vue"; 
import Audio from "@/view/audio.vue"; 
import Video from "@/view/video.vue"
import bestVideo from "@/view/bestVideo.vue"

Vue.use(Router)
export const constantRouterMap =[
    {
        path:'/',
        name:'首页',
        redirect:'/Index',
        menuShow:false
    },
    {
        path:'/index',
        name:'首页',
        component: Index,
        menuShow:false
    },
    {
        path:'/rules',
        name:'规则页',
        component: Rules,
        menuShow:false
    },
    {
        path:'/map',
        name:'地图页',
        component: Map,
        menuShow:false
    },
    {
        path:'/deliver',
        name:'选择医生页',
        component: Deliver,
        menuShow:false
    },
    {
        path:'/letter',
        name:'信件页',
        component: Letter,
        menuShow:false
    },
    {
        path:'/uploadFile',
        name:'上传文件页',
        component: UploadFile,
        menuShow:false
    },
    {
        path:'/endPage',
        name:'上传文件页',
        component: EndPage,
        menuShow:false
    },
    {
        path:'/audio',
        name:'上传录音页',
        component: Audio,
        menuShow:false
    },
    {
        path: '/video',
        name: '上传视频页',
        component: Video,
        menuShow: false
    },
    {
        path:'/bestvideo',
        name:'上传佳音页',
        component:bestVideo,
        menuShow:false
    },
    {
        path:'/login',
        name:'登录页',
        component: Login,
        menuShow:false
    },
    {
        path: "*",
        redirect: "/index"
    }
]

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
 
export default new Router({
    routes: constantRouterMap
})