<template>
  <div class="login-page">
    <!-- logo -->
    <div class="logo-box">
      <div class="left">
        <img class="animated fadeInLeft" src="@/assets/img/index/logo1.png" alt="" srcset="" />
      </div>
      <div class="right">
        <img class="animated fadeInRight" src="@/assets/img/index/logo2.png" alt="" srcset="" />
      </div>
    </div>
    <!-- nav -->
    <div class="nav-box dongdata1">
      <img src="@/assets/img/index/nav.png" alt="" srcset="" />
    </div>

    <div class="uploadFile-box" @click="uploadAudio()">
      <input type="file" accept="audio/*" style="display: none" @change="changeAudio()"
        name="upload_file" ref="inputA" />
      <img src="@/assets/img/uploadFile/upAudio.png" alt="" srcset="" />
    </div>
    <div class="uploadFile-box" @click="uploadVideo()">
      <input type="file" accept="video/*" capture="microphone" style="display: none" @change="changeVideo()"
        name="upload_file" ref="inputB" />
      <img src="@/assets/img/uploadFile/upVideo.png" alt="" srcset="" />
    </div>
    <div class="uploadFile-box" @click="uploadText()">
      <img src="@/assets/img/uploadFile/upWord.png" alt="" srcset="" />
    </div>
    <div class="Aquestion-textarea">
      <textarea placeholder="文本" v-model="ViewPoint" name="" id="" cols="30" rows="10"></textarea>
    </div>
    <loading v-show="loadingShow"></loading>
  </div>
</template>
<script>
import OSS from "ali-oss";
import loading from "@/components/loading";
import Header from "@/components/Header";
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import qs from "qs";
export default {
  data() {
    return {
      loadingShow: false,
      openid: "",
      ViewPoint: "",
      ViewPointShow:false
    };
  },
  components: {
    loading: loading,
    Header: Header,
  },
  mounted() {
    this.getMsg();
  },
  methods: {
    uploadAudio() {
      this.$emit("audioAnimationT");
      //this.$refs.inputA.dispatchEvent(new MouseEvent("click"));
      this.$router.replace('/audio')
    },
    uploadVideo() {
      this.$emit("audioAnimationT");
      this.$router.replace("/video")
      // this.$router.replace("/video")
      //this.$refs.inputB.dispatchEvent(new MouseEvent("click"));
    },
    uploadText() {
      let that = this;
      if(!this.ViewPointShow) {
        if (that.ViewPoint == "") {
          Dialog.alert({
            title: "提示",
            message: "请输入您的观点",
          }).then(() => {
            // on close
          });
        } else {
          let promise = {
            id: sessionStorage.getItem("userid"),
            ViewPoint: that.ViewPoint,
          };
          that.axios.post("/UpViewPoint", promise).then(function (res) {
            console.log(res.data);
            Dialog.alert({
                title: "提示",
                message: res.data.Message,
              }).then(() => {
                // on close
                that.$router.replace('/map');
              });
          });
        }
      } else {
        Dialog.alert({
          title: "提示",
          message: '您已发表过此观点 \n 请勿重复操作!',
        }).then(() => {
          // on close
          that.$router.replace('/map');
        });
      }

    },
    getMsg() {
      let that = this;
      let promise = {
        FullName: sessionStorage.getItem('FullName'),
      }
      that.axios.post('/GetMsgRecord',
        promise
      ).then(function (res) {
        sessionStorage.setItem('userid', res.data.Data.id);
        if (res.data.Success && res.data.Data && res.data.Data.ViewPoint) {
          that.ViewPointShow = true;
        } else {
          that.ViewPointShow = false;
        }
      })
    },
    changeAudio(type) {
      var files = this.$refs.inputA.files;
      this.Upload(files[0], type);
    },
    changeVideo(type) {
      let videoFiles = this.$refs.inputB.files;
      this.Upload(videoFiles[0], type)
    },
    //自定义上传方法..
    Upload(file) {
      console.log(file)
      this.loadingShow = true;
      var name = "2023/cp/feishu/回答视频和音频";
      let FullName = sessionStorage.getItem('FullName')
      let fileName = name + "/" + FullName + file.name;
      const _this = this;
      _this.axios
        .get(
          "https://webapi.qixqir.com/api/oss/getstsuser?itemno=S561294c94f50708c5d671b2D1H6D58"
        )
        .then((result) => {
          _this.client = new OSS({
            region: "oss-cn-beijing",
            accessKeyId: result.data.Data.AccessKeyId,
            accessKeySecret: result.data.Data.AccessKeySecret,
            stsToken: result.data.Data.SecurityToken,
            bucket: "ossqixqir",
          });
          this.getBase64(file).then((resBase64) => {
            const base64 = resBase64.split(",").pop();
            const fileType = resBase64.split(";").shift().split(":").pop();
            // base64转blob
            const blob = _this.dataURLtoBlob(base64, fileType);
            // blob转arrayBuffer
            const reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            console.log(reader);
            reader.onload = (event) => {
              //arrayBuffer转Buffer
              const buffer = OSS.Buffer(event.target.result);
              // 上传
              _this.client
                .put(fileName, buffer)
                .then((result) => {
                  console.log(result);
                  //let httpUrl = 'https://merckoss.wfbweb.com/';
                  _this.fileUploadChange(result.url);
                  _this.$emit("audioAnimationF");

                  // _this.adduser.agree_img =  httpUrl+fileName;
                })
                .catch((err) => {
                  console.log("错误", err);
                });
            };
          });
        });
    },

    //file文件转base64
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file);
        //开始转
        reader.onload = function () {
          fileResult = reader.result;
        };
        //转 失败
        reader.onerror = function (error) {
          reject(error);
        };
        //转 结束
        reader.onloadend = function () {
          resolve(fileResult);
        };
      });
    },

    //base64转blob
    dataURLtoBlob(urlData, fileType) {
      let bytes = window.atob(urlData);
      let n = bytes.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bytes.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: fileType,
      });
    },

    fileUploadChange(url) {
      let that = this;
      let promise = {
        "OpenID": sessionStorage.getItem('openid'),
        "FullName": sessionStorage.getItem('FullName'),
        "Hospital": sessionStorage.getItem('Hospital'),
        "Region": sessionStorage.getItem('Region'),
        "VideoUrl": url
      };
      that.axios.post("/UpVideo", promise).then(function (res) {
        console.log(res.data);
        if (res.data.Success) {
          that.loadingShow = false;
          that.$router.replace('/map')
        } else {
          Dialog.alert({
            title: "提示",
            message: res.data.Message,
          }).then(() => {
            // on close
          });
        }
      });
    },

    nextChange() {
      this.$router.replace("/map");
    },
  },
};
</script>
<style scoped>
.logo-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.left {
  margin-left: 30px;
  margin-top: 30px;
  text-align: left;
}

.left img {
  width: 123px;
}

.right {
  margin-right: 30px;
  margin-top: 70px;
  text-align: right;
}

.right img {
  width: 164px;
}

/* nav */
.nav-box {
  width: 100%;
  margin-top: 10px;
}

/* title */
.title-box {
  width: 79%;
  margin: 0 auto;
  margin-top: 24px;
}

.inviteRule-title {
  width: 578px;
  margin: 0 auto;
  margin-top: 36px;
}

.inviteRule-select {
  width: 467px;
  margin: 0 auto;
  margin-top: 40px;
}

.Aquestion {
  width: 397px;
  margin: 0 auto;
  margin-top: 213px;
}

.Aquestion-textarea textarea {
  width: 483px;
  height: 120px;
  margin-top: 25px;
  border-radius: 25px;
  border: 1pt solid #dfdfdf;
  font-size: 24px;
  padding: 10px;
  box-sizing: border-box;
}

.login-btn {
  width: 522px;
  margin: 0 auto;
  margin-top: 45px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 220px);
  grid-row-gap: 39px;
  grid-column-gap: 39px;
}

.uploadFile-box {
  width: 300px;
  margin: 0 auto;
  margin-top: 100px;
}

.uploadFile-box:nth-of-type(1) {
  margin-top: 200px;
}
</style>