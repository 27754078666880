<template>
    <div class="login-page">
       <!-- logo -->
       <!-- <div class="logo-box">
            <div class="left">
                <img class="animated fadeInLeft" src="@/assets/img/index/logo1.png" alt="" srcset="">
            </div>
            <div class="right">
                <img class="animated fadeInRight" src="@/assets/img/index/logo2.png" alt="" srcset="">
            </div>
        </div> -->
        <!-- nav -->
        <!-- <div class="nav-box dongdata1">
            <img src="@/assets/img/endPage/top-first.png" alt="" srcset="">
        </div> -->
        <Header2></Header2>
        <div class="content-box">
            <img src="@/assets/img/endPage/lastInfo.png" alt="" srcset="">
        </div>
        <div class="end-box">
            <img class="back" @click="backMap()" src="../assets/img/letter/btn-back.png" alt="">
            <!-- <img class="bottomImg" src="@/assets/img/endPage/top-second.png" alt="" srcset=""> -->
        </div>
        <loading v-show="loadingShow"></loading>
    </div>
</template>
<script>
import loading from '@/components/loading'
import Header2 from '@/components/Header2'
import Vue from 'vue'
import { Dialog } from 'vant';
Vue.use(Dialog);
import qs from 'qs'
export default {
    data() {
        return {
            loadingShow: false,
            openid: '',
        }
    },
    components: {
        'loading': loading,
        'Header2': Header2
    },
    mounted() {
        let openid = this.$cookies.get("hengrui0613_openid");
    },
    methods: {
        nextChange() {
            this.$router.replace('/map')
        },
        backMap(){
            this.$router.replace('/map')
        }
    }
}
</script>
<style scoped>

.logo-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}

.left {

    margin-left: 30px;
    margin-top: 30px;
    text-align: left;
}

.left img {
    width: 123px;
}

.right {
    margin-right: 30px;
    margin-top: 70px;
    text-align: right;
}

.right img {
    width: 164px;
}

/* nav */
.nav-box {
    width: 79.2%;
    margin: 0 auto;
    margin-top: 100px;
}
.dongdata1 {
    animation: clip 1s linear 0.5s forwards;
    opacity: 0;
}

@keyframes clip {
    0% {
        opacity: 0;
        clip-path: polygon(0 0, 35% 0, 0 68%, 0 67%);
    }

    10% {
        opacity: 1;
        clip-path: polygon(0 0, 35% 0, 0 99%, 0% 100%);
    }

    30% {
        opacity: 1;
        clip-path: polygon(0 0, 75% 0, 34% 100%, 0 100%);
    }

    50% {
        opacity: 1;
        clip-path: polygon(0 0, 100% 0, 51% 100%, 0 100%);
    }

    70% {
        opacity: 1;
        clip-path: polygon(0 0, 100% 0, 74% 100%, 0 100%);
    }

    100% {
        opacity: 1;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
}
.content-box{
    width: 79.2%;
    margin: 0 auto;
    margin-top: 100px;
}
.end-box{
    width: 100%;
    height: 100px;
    margin: 0 auto;
    margin-top: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.back{
    display: block;
    width: 222px;
    bottom: 0;
}
</style>