<template>
  <div class="login-page">
    <div class="loading" v-if="loading">
      <van-loading color="white" type="spinner" vertical>
        提交中...
      </van-loading>
    </div>
    <Header></Header>
    <div class="upBox">
      <div class="upVideo">
        <!-- <img src="../assets/img/video/videoMb.png" alt=""> -->
        <van-uploader
          accept="video/*"
          :after-read="videoOK"
          result-type="dataUrl"
          v-if="videoUp"
        >
        </van-uploader>
        <!-- <video class="videoMb" v-if="videourl" controls>
          <source :src="videourl" />
        </video> -->
        <div class="videoMb" v-if="videourl"><svg t="1684919881027" class="icon" viewBox="0 0 1336 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3565" width="100" height="100"><path d="M49.588224 883.816448M1139.531776 883.816448M86.939648 862.977024M538.459136 960.381952c109.876224-153.544704 223.55968-289.842176 308.03968-385.787904 49.87392-56.639488 96.715776-108.155904 141.549568-154.500096 40.576-41.945088 80.805888-80.405504 119.478272-116.59264 65.92-61.693952 142.984192-130.034688 191.446016-159.30368l-61.891584-82.050048c-89.732096 56.139776-176.10752 116.103168-242.799616 162.18112-38.863872 26.855424-74.928128 52.667392-108.915712 77.252608-33.668096 24.356864-68.429824 51.106816-105.081856 79.166464-63.633408 48.712704-145.388544 114.194432-224.555008 181.860352L357.07904 374.989824 123.885568 558.770176 538.459136 960.381952zM1335.92064 862.977024" fill="#ffffff" p-id="3566"></path></svg></div>
      </div>
      <div class="btnBox">
        <div class="delete" @click="deleteVideo">清除</div>
        <div class="uping" @click="upingVideo">上传</div>
      </div>
    </div>
  </div>
</template>

<script>
import OSSUpdata from "../common/util/ossupdata.js";
import loading from "@/components/loading";
import Header from "@/components/Header";
import Vue from "vue";
import { Dialog, Uploader } from "vant";
Vue.use(Dialog);
Vue.use(Uploader);
export default {
  data() {
    return {
      //需要展示的视频
      videourl: "",
      //是否已经上传
      videoisOk: false,
      //上传按钮展示
      videoUp: true,
      //加载中展示
      loading: false,
      //需要上传的视频
      userinfo:{
        OpenID:"",
        FullName:"",
        Hospital:"",
        Region:"",
        VideoUrl:""
      },
      isok:false,
    };
  },
  components: {
    loading: loading,
    Header: Header,
  },
  created() {
    OSSUpdata.init();
  },
  methods: {
    tc(info) {
      this.$dialog.alert({
        title: "",
        message: info,
        confirmButtonColor: "#961e5f",
      });
    },
    //选好视频了
    videoOK(file) {
      // console.log(file)
      this.videoUp = false;
      this.videourl = file.content;
      this.videoisOk = true;
    },
    //删除选中视频
    deleteVideo() {
      if (this.videoisOk == false) {
        this.tc("<h3>您还未选择视频</h3>");
      } else {
        this.videourl = "";
        this.videoisOk = false;
        this.videoUp = true;
      }
    },
    //正式上传到OSS
    upingVideo() {
      if (this.videoisOk == false) {
        this.tc("<h3>您尚未选择视频</h3>");
      } else {
        this.loading = true;
        let _this = this;
        OSSUpdata.uploadBase64(
          this.videourl,
          `佳音视频/`+sessionStorage.getItem('FullName'),
          "video/mp4"
        ).then((res) => {
          _this.userinfo.VideoUrl = res;
          _this.userinfo.FullName = sessionStorage.getItem('FullName')
          _this.userinfo.Region = sessionStorage.getItem('Region')
          _this.userinfo.Hospital = sessionStorage.getItem('Hospital')
          //这条ID没整上
           _this.userinfo.OpenID = sessionStorage.getItem('openid')
          _this
            .$post(
              "http://webapi.qixqir.com/api/C20230518/UpVideo",
              _this.userinfo
            )
            .then((res) => {
              if (res.Success == true) {
                console.log(res)
                _this.$emit("audioAnimationF");
              }
            });
        });
        setTimeout(() => {
          _this.loading = false;
          // _this.tc("<h3>提交成功</h3>");
          _this.$router.replace('/map')
        }, 1500);
      }
    },
  },
};
</script>
<style scoped>
.loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999999;
  background-color: rgba(0, 0, 0, 0.78);
}
.upBox {
  width: 660px;
  height: 600px;
  position: relative;
  margin: 0 auto;
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.upVideo {
  width: 400px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.videoMb {
  width: 400px;
  height: 400px;
  /* background-color: rgba(7, 193, 96, 0.78); */
  background-color: #961e5f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 188px;
  font-weight: 800;
  box-sizing: border-box;
  /* border: 10px solid #961e5f; */
  border-radius: 200px;
}
/* .upVideo img {
  width: 400px;
  height: 400px;
} */
.btnBox {
  width: 660px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.btnBox div {
  width: 180px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #961e5f;
  color: white;
  font-size: 38px;
  font-weight: 600;
}
div /deep/.van-uploader__upload {
  width: 400px;
  height: 400px;
  margin: 0;
  border: 10px solid #961e5f;
  border-radius: 15px;
  box-sizing: border-box;
  background-color: rgba(150, 30, 95, 0.28);
}
div /deep/.van-uploader__upload-icon {
  width: 200px;
  height: 200px;
  font-size: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
div /deep/.van-loading__text {
  font-size: 40px;
  margin-top: 20px;
}
div /deep/.van-loading__spinner{
  width: 75px;
  height: 75px;
}
</style>


