import $ from 'jquery'
export default {
  data: {
    title: "加贝守护 力博生机",
    desc: "热烈庆祝贝博萨中国上市一周年",
    imgUrl: "https://oss.qixqir.com/2023/cp/public/feishu.jpg",
    link: "http://webapi.qixqir.com/wx/C20230518",
  },
  init: function () {
    $.getJSON(
      "http://webapi.qixqir.com/api/JsSdk/GetWxJsSign?itemno=S561294c94f50708c5d671b2D1H6D58",
      { url: location.href.split("#")[0] },
      (result) => {
        console.log(result)
        if (result) {
          var config = {
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result.Data.appId, // 必填，公众号的唯一标识
            timestamp: result.Data.timestamp, // 必填，生成签名的时间戳
            nonceStr: result.Data.nonceStr, // 必填，生成签名的随机串
            signature: result.Data.signature, // 必填，签名，见附录1
            jsApiList: [
              'openLocation',
              'closeWindow',
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "startRecord",
              "stopRecord",
              "uploadVoice",
              "playVoice",
              "pauseVoice",
              "stopVoice",
              "onVoicePlayEnd",
              "onVoiceRecordEnd",
            ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          };
          wx.config(config);
          this.share()
        }
      }
    );
  },
  share: function () {
    var self = this;
    wx.ready(function () {
      console.log(self.data)
      var config = {
        link: self.data.link,
        imgUrl: self.data.imgUrl,
        title: self.data.title,
        desc: self.data.desc,
        success: function () { },
        cancel: function () {
          // 用户取消分享后执行的回调函数
        }
      };
      wx.onMenuShareTimeline(config);
      wx.onMenuShareAppMessage(config);
    })
  },
  close: function () {
    wx.closeWindow()
  }
};
