<template>
  <div id="app">
    <router-view @audioAnimationT="audioAnimationT" @audioAnimationF="audioAnimationF"></router-view>
    <Bjmusic ref="bgm"></Bjmusic>
  </div>
</template>

<script>
import weixinShare from "./util/wxconfig";
import Bjmusic from "./components/bjmusic/bgm.vue";
import { Dialog } from "vant"
import Vue from 'vue'
Vue.use(Dialog)

export default {
  name: 'App',
  components: {
    Bjmusic,
  },
  data() {
    return {

    }
  },
  methods: {
    audioAnimationT() {
      this.$refs.bgm.audioAnimationT();
    },
    audioAnimationF() {
      this.$refs.bgm.audioAnimationF();
    },
  },
  created() {
    weixinShare.init();
    // this.$cookies.set("cardopenid", "okQ_46Tx3ZZdm4GPMbYndTYu_UtQ");
  },
  mounted() {
   
  },
}
</script>

<style>
@import './assets/css/animate.css';

html,
body {
  width: 100%;
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #303030;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background: #fbfdfc;
  margin: 0;
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
dd,
dt,
dl,
form,
kbd,
table,
ul,
li,
marquee,
hr,
blockquote,
ol,
pre,
fieldset,
legend,
button,
input,
textarea,
th,
td {
  font-size: 12px;
  margin: 0;
  padding: 0;
  font-family: "微软雅黑", Arial, "宋体", Tahoma, Verdana, Helvetica, sans-serif;
}

address,
cite,
dfn,
em,
var {
  font-style: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  cursor: pointer
}

input {
  cursor: pointer
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ul,
ol {
  list-style: none;
}

img {
  border: 0;
  width: 100%;
}

ul {
  list-style: none;
}

.clear {
  clear: both;
}

a {
  text-decoration: none;
  color: #666666;
}

a:hover {
  color: #000;
  text-decoration: none
}

input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="text"],
button {
  -webkit-appearance: none;
  border-radius: 0;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
  -ms-overflow-style: none;
  /* IE 10+ */
  scrollbar-width: none;
  /* Firefox */
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #adadad;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #adadad;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #adadad;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #adadad;
}
</style>
<style>
.content-user-sex .van-radio__icon {
  font-size: 4vw;
  height: auto;
  line-height: 2;
}

.van-picker-select {
  width: 100%;
  height: 35vh;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 5;
}

.van-picker-model {
  width: 100vw;
  height: 100%;
  background: #000;
  opacity: 0.3;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.van-picker-select .van-picker__cancel,
.van-picker-select .van-picker__confirm {
  font-size: 4.5vw;
}

.van-picker-select .van-picker__confirm {
  color: #a60060;
}

.van-picker-select .van-ellipsis {
  font-size: 4.5vw;
}

.van-picker-select .van-picker__toolbar {
  height: 10vw;
}

.van-dialog {
  width: 70% !important;
}

.van-dialog__header {
  font-size: 5vw;
}

.van-dialog__message {
  line-height: 1.5 !important;
  font-size: 5vw !important;
  margin-top: 3vw;
}
.van-dialog__message h3{
  font-size: 4vw;
}
.van-dialog__confirm,
.van-dialog__confirm:active {
  color: #a60060 !important;
  height: 6vh !important;
}

.van-dialog__footer {
  margin-top: 3vw;
}

.van-button__text {
  font-size: 5vw !important;
}

.van-radio__label {
  font-size: 28px;
}
</style>