<template>
  <div class="login-page">
    <Header></Header>
    <div class="ruleTitle">
      <img src="@/assets/img/rules/ruleTitle.png" alt="" srcset="" />
    </div>
    <div class="rule-content">
      <img src="@/assets/img/rules/rules.png" alt="" srcset="" />
    </div>
    <div class="login-btn" @click="nextChange()">
      <input type="file" accept="video/*" capture="microphone" style="display: none" @change="VideoCollect()"
        name="upload_file" ref="inputC" />
      <img src="@/assets/img/rules/upLoad.png" alt="" srcset="" />
    </div>
    <loading v-show="loadingShow"></loading>
  </div>
</template>
<script>
import OSS from "ali-oss";
import loading from "@/components/loading";
import Header from "@/components/Header";
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import qs from "qs";
export default {
  data() {
    return {
      loadingShow: false,
      openid: "",
    };
  },
  components: {
    loading: loading,
    Header: Header,
  },
  mounted() {
    let openid = this.$cookies.get("hengrui0613_openid");
  },
  methods: {
    nextChange() {
      this.$emit("audioAnimationT");
      // this.$router.replace('/video')
      this.$router.replace('/bestvideo')
      //this.$refs.inputC.dispatchEvent(new MouseEvent("click"));
    },
    VideoCollect(type) {
      let bestVideo = this.$refs.inputC.files;
      this.Upload(bestVideo[0], type);
    },
    //自定义上传方法..
    Upload(file) {
      this.loadingShow = true;
      let FullName = sessionStorage.getItem('FullName')
      var name = "2023/cp/feishu/佳音视频";
      let fileName = name + "/" + FullName + file.name;
      const _this = this;
      _this.axios
        .get(
          "https://webapi.qixqir.com/api/oss/getstsuser?itemno=S561294c94f50708c5d671b2D1H6D58"
        )
        .then((result) => {
          console.log(result.data);
          _this.client = new OSS({
            region: "oss-cn-beijing",
            accessKeyId: result.data.Data.AccessKeyId,
            accessKeySecret: result.data.Data.AccessKeySecret,
            stsToken: result.data.Data.SecurityToken,
            bucket: "ossqixqir",
          });
          this.getBase64(file).then((resBase64) => {
            const base64 = resBase64.split(",").pop();
            const fileType = resBase64.split(";").shift().split(":").pop();
            // base64转blob
            const blob = _this.dataURLtoBlob(base64, fileType);
            // blob转arrayBuffer
            const reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            console.log(reader);
            reader.onload = (event) => {
              //arrayBuffer转Buffer
              const buffer = OSS.Buffer(event.target.result);
              // 上传
              _this.client
                .put(fileName, buffer)
                .then((result) => {
                  console.log(result);
                  //let httpUrl = 'https://merckoss.wfbweb.com/';
                  _this.audioUrl = result.url;
                  _this.loadingShow = false;
                  _this.fileUploadChange(result.url);
                  _this.$emit("audioAnimationF");
                  // _this.adduser.agree_img =  httpUrl+fileName;
                })
                .catch((err) => {
                  console.log("错误", err);
                });
            };
          });
        });
    },
    //file文件转base64
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file);
        //开始转
        reader.onload = function () {
          fileResult = reader.result;
        };
        //转 失败
        reader.onerror = function (error) {
          reject(error);
        };
        //转 结束
        reader.onloadend = function () {
          resolve(fileResult);
        };
      });
    },
    //base64转blob
    dataURLtoBlob(urlData, fileType) {
      let bytes = window.atob(urlData);
      let n = bytes.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bytes.charCodeAt(n);
      }
      return new Blob([u8arr], {
        type: fileType,
      });
    },
    fileUploadChange(url) {
      let that = this;
      let promise = {
        "OpenID": sessionStorage.getItem('openid'),
        "FullName": sessionStorage.getItem('FullName'),
        "Hospital": sessionStorage.getItem('Hospital'),
        "Region": sessionStorage.getItem('Region'),
        "VideoUrl": url
      };
      that.axios.post("/UpVideo", promise).then(function (res) {
        console.log(res.data);
        if (res.data.Success) {
          that.loadingShow = false;
          that.$router.replace('/map')
        } else {
          Dialog.alert({
            title: "提示",
            message: res.data.Message,
          }).then(() => {
            // on close
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.ruleTitle {
  width: 591px;
  margin: 0 auto;
  margin-top: 44px;
}

.login-btn {
  width: 222px;
  margin: 0 auto;
  margin-top: 76px;
}

.rule-content {
  width: 630px;
  margin: 0 auto;
  margin-top: 64px;
}
</style>