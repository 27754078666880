<template>
    <div class="login-page">
        <Header></Header>
        <div class="user-content-box">
            <div class="user-name animated  fadeInLeft">
                <input v-model="FullName" placeholder="姓名" @blur="GetLight" type="text"/>
            </div>
            <div class="user-name user-hospital animated  fadeInRight">
                <input v-model="Hospital" placeholder="医院" type="text"/>
            </div>
            <div class="user-name user-province animated slideInUp" @click="getUserName()">
                <input v-model="Region" readonly placeholder="省份" type="text"/>
            </div>
        </div>
        <div v-if="LightShow" class="LightRegion-box">
            <p>您被{{ LightRegion }}的{{ LightFullName }}医生点名,</p>
            <p>期待您继续点亮生命之旅！</p>
        </div>
        <div class="login-btn" @click="loginChange()">
            <img src="@/assets/img/login/start.png" alt="" srcset="">
        </div>
        <loading v-show="loadingShow"></loading>
        <vanPickerSelect ref="PickerSelect" @onPickerConfirm="onPickerConfirm" @func="getMsgFormSon"></vanPickerSelect>
    </div>
</template>
<script>
import vanPickerSelect from '@/components/van-picker-select'
import loading from '@/components/loading'
import Header from '@/components/Header'
import Vue from 'vue'
import { Dialog } from 'vant';
Vue.use(Dialog);
import qs from 'qs'
export default {
    data() {
        return {
            userInfoList: [],
            userInfoListSelct: ['北京市', '天津市', '河北省', '山西省', '内蒙古自治区', '辽宁省', '吉林省', '黑龙江省', '上海市', '江苏省', '浙江省', '安徽省', '福建省', '江西省', '山东省', '河南省', '湖北省', '湖南省', '广东省', '广西壮族自治区', '海南省', '四川省', '贵州省', '云南省', '重庆市', '西藏自治区', '陕西省', '甘肃省', '青海省', '宁夏回族自治区', '新疆维吾尔自治区', '香港特别行政区', '澳门特别行政区', '台湾省'],
            loadingShow: false,
            openid: '',
            FullName: '',
            Hospital: '',
            Region: '',
            LightShow: false,
            LightRegion: '',
            LightFullName: ''
        }
    },
    components: {
        'vanPickerSelect': vanPickerSelect,
        'loading': loading,
        'Header': Header
    },
    mounted() {
        let openid = sessionStorage.getItem('openid');
        if (openid) {
            this.openid = openid;
            //this.$router.replace('/rules');
        } else {

            //window.location.replace('https://h5.lehuitech.com.cn/qr/qrred?k=86MWJ45H');
        }
    },
    methods: {
        getUserName() {
            this.$refs.PickerSelect.onPickerConfirm(this.userInfoListSelct);
        },
        GetLight() {
            let that = this;
            let promise = {
                FullName: that.FullName,
            }
            that.axios.post('/GetLight',
                promise
            ).then(function (res) {
                console.log(res.data);
                if (res.data.Success) {
                    console.log(res.data.Data);
                    that.LightRegion = res.data.Data.Region;
                    that.LightFullName = res.data.Data.FullName;
                    that.LightShow = true;
                    console.log('有点亮');
                } else {
                    that.LightShow = false;
                    console.log('没有点亮');
                }
            })
        },

        //传值
        onPickerConfirm(res) {
            tihs.$emit(res);
        },

        //取值
        getMsgFormSon(data) {
            this.Region = data;
        },

        //登录
        loginChange() {
            let that = this;
            if (that.FullName == '') {
                Dialog.alert({
                    title: '提示',
                    message: '请输入姓名',
                }).then(() => {
                    // on close
                });
            } else if (that.Hospital == '') {
                Dialog.alert({
                    title: '提示',
                    message: '请输入医院',
                }).then(() => {
                    // on close
                });
            } else if (that.Region == '') {
                Dialog.alert({
                    title: '提示',
                    message: '请选择省份',
                }).then(() => {
                    // on close
                });
            }
            else {
                that.loadingShow = true;
                let promise = {
                    OpenID: that.openid,
                    FullName: that.FullName,
                    Hospital: that.Hospital,
                    Region: that.Region
                }
                that.axios.post('/Login',
                    promise
                ).then(function (res) {
                    console.log(res.data);
                    if (res.data.Success && res.data.Code == '1') {
                        console.log(res.data.Data)
                       
                        sessionStorage.setItem('Region', res.data.Data.Region);
                        sessionStorage.setItem('FullName', res.data.Data.FullName);
                        sessionStorage.setItem('Hospital', res.data.Data.Hospital);
                        that.$router.replace('/rules');
                        that.loadingShow = false;
                    } else {
                        that.loadingShow = false;
                        Dialog.alert({
                            title: '提示',
                            message: res.data.Message,
                        }).then(() => {
                            // on close
                        });
                    }
                })
            }

        },
    }
}
</script>
<style scoped>
.user-content-box {
    width: 100%;
    margin-top: 90px;
}

.user-name {
    width: 464px;
    height: 82px;
    margin: 0 auto;
    background: url(../assets/img/login/name.png)no-repeat center;
    background-size: 100% 100%;
    margin-top: 60px;
}

.user-name input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    border-radius: 25px;
    padding-left: 120px;
    font-size: 30px;
    box-sizing: border-box;
    text-align: left;
}

.user-hospital {
    background: url(../assets/img/login/hospital.png)no-repeat center;
    background-size: 100% 100%;
}

.user-province {
    background: url(../assets/img/login/province.png)no-repeat center;
    background-size: 100% 100%;
}

.login-btn {
    width: 222px;
    margin: 0 auto;
    margin-top: 152px;
}

.LightRegion-box {
    margin-top: 30px;
}

.LightRegion-box p {
    font-size: 30px;
    color: #a60060;
    font-weight: 800;
    margin: 0;
    line-height: 1.5;
    text-decoration: underline;
    animation: breath-effect 2s ease-in-out infinite;
}

@keyframes breath-effect {
    0% {
        transform: scale(1.0);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1.0);
    }
}</style>