<template>
    <div class="login-page">
        <Header></Header>
        <h3>录音上传</h3>
        <div>
            <h3>录音状态：{{ readyOriginalResult }}</h3>
            <br />
            <button type="primary" @click="vioceStart()">开始录音</button>
            <button type="info" @click="vioceEnd()">结束上传</button>
        </div>
        <div class="login-btn" @click="nextChange()">
            <img src="@/assets/img/letter/btn-back.png" alt="" srcset="">
        </div>
    </div>
</template>
  
<script>
import OSS from "ali-oss"
import loading from '@/components/loading'
import Header from '@/components/Header'
import Vue from 'vue'
import { Dialog } from 'vant';
Vue.use(Dialog);
export default {
    data() {
        return {
            recorder: null,
            playTime: 0,
            timer: null,
            src: null,

            localId: '',
            iconSuccess: false,
            iconError: false,
            oneOnOneShow: false,
            readyOriginalShow: false,
            voiceText: '开始录音',
            readyOriginalResult: '等待录音',
            JsTocken: null,
            clicked: false
        }
    },
    components: {
        'loading': loading,
        'Header': Header
    },
    created() {

    },
    mounted() {
        wx.ready(() => {
            wx.onVoiceRecordEnd({
                complete: (res) => {
                    console.log(res);
                    this.localId = res.localId;
                },
            });
            wx.onVoicePlayEnd({
                success: (res) => {
                    console.log(res)
                },
            });
        });
    },
    methods: {
        //开始录音
        vioceStart() {
            if (this.clicked) {
                return;
            };
            this.$emit("audioAnimationT");
            this.clicked = true;
            wx.startRecord({
                success: () => {
                    this.readyOriginalResult = '正在录音...';
                },
                cancel: function () {
                    this.readyOriginalResult = '拒绝授权录音...';
                },
                complete: () => {
                    // this.clicked = false;
                },
            });
        },

        //结束录音
        vioceEnd() {
            let that = this;
            wx.stopRecord({
                success: function (res) {
                    console.log(that.localId);
                    that.localId = res.localId;
                    that.uploadVoice();
                },
                fail: function (res) {
                    console.log(JSON.stringify(res));
                },
                complete: () => {
                    // this.clicked = false;
                    // this.isStarted = false;
                },
            });
        },

        uploadVoice() {
            let that = this;
            wx.uploadVoice({
                localId: this.localId, // 需要上传的音频的本地ID，由stopRecord接口获得
                isShowProgressTips: 1, // 默认为1，显示进度提示
                success: (res) => {
                    var serverId = res.serverId; // 返回音频的服务器端ID
                    console.log(serverId);
                    that.getMeiderFile(serverId);

                },
            });
        },

        getMeiderFile(serverId) {
            let that = this;
            this.axios.get("https://webapi.qixqir.com/api/C20230518/GetUserWxAudio?server_id=" + serverId).then((res) => {
                console.log(res.data);
                that.readyOriginalResult = '正在上传...'
                that.fileUploadChange(res.data.Data);
            });
        },
        
        fileUploadChange(url) {
            let that = this;
            let promise = {
                "OpenID": sessionStorage.getItem('openid'),
                "FullName": sessionStorage.getItem('FullName'),
                "Hospital": sessionStorage.getItem('Hospital'),
                "Region": sessionStorage.getItem('Region'),
                "VideoUrl": url
            };
            that.axios.post("/UpVideo", promise).then(function (res) {
                console.log(res.data);
                if (res.data.Success) {
                    that.loadingShow = false;
                    that.$emit("audioAnimationF");
                    that.$router.replace('/map')
                } else {
                    Dialog.alert({
                        title: "提示",
                        message: res.data.Message,
                    }).then(() => {
                        // on close
                    });
                }
            });
        },

        nextChange() {
            this.$router.replace('/uploadFile')
        },



    }
}
</script>
<style scoped>
.login-page h3 {
    font-size: 30px;
    margin-top: 100px;
}

.login-page button {
    padding: 10px;
    font-size: 30px;
    background: #961e5f;
    color: #fff;
    border: 1pt solid #fff;
}

.login-btn {
    width: 222px;
    margin: 0 auto;
    margin-top: 176px;
}
</style>
  
  
  